import React from 'react';
import styled from 'styled-components';

const SelectedPosesAndBackgroundsContainer = styled.div`
  padding-bottom: 32px;
`;

const StudentPreviewContainer = styled.div`
  margin-bottom: 16px;
  float: none;
`;

const SelectedConfigWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 2;
  &:hover {
    cursor: pointer;
  }
`;

const Box = styled.div`
  height: 150px;
  width: 120px;
  background-color: #e0dedf;
  position: relative;
  background-image: ${(props) => props.background};
  background-size: cover;
  background-position: center;
  border: 1px solid darkgrey;
  margin: 0 12px;
  margin-top: ${(props) => (props.isShifted ? '24px' : '0')};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const BoxText = styled.div`
  position: absolute;
  bottom: -24px;
  left: 50%;
  transform: translateX(-50%);
  color: #555555;
  font-size: 14px;
`;

const SelectedPosesAndBackgrounds = ({
  backgroundSelections,
  poseSelections,
  isGreenScreen,
  isPrepay,
  posesCount,
  productBackgroundOptionsCount,
  studentPreviewImageUrl,
  togglePosesBackgroundsSelector, // TODO: remove this(?)
  selectedIndex,
  setSelectedIndex,
  setShowPoseOptions,
  updatedPosesCount,
}) => {
  let productRequiresConfiguration = true;
  productRequiresConfiguration =
    productBackgroundOptionsCount && productBackgroundOptionsCount > 0;

  const handleToggle = (index) => {
    setSelectedIndex((prevIndex) => (prevIndex === index ? prevIndex : index));
  };

  const renderBackgroundImage = (index, backgroundOptionsCount) => {
    if (backgroundSelections.length > 0 && backgroundSelections[index]) {
      return (
        <>
          {!isPrepay && studentPreviewImageUrl && (
            <StudentPreviewContainer className="bnl-product-config__selected-pose">
              <img
                className="bnl-product-config__selected-pose__image portrait-image"
                src={studentPreviewImageUrl}
              />
            </StudentPreviewContainer>
          )}
          <div className="bnl-product-config__selected-background bnl-product-config__selected-background--pre-shoot">
            <img
              className="bnl-product-config__selected-background__image"
              src={backgroundSelections[index]?.image_thumbnail}
            />
          </div>
        </>
      );
    }

    return (
      <>
        <div style={{ position: 'relative' }}>
          {!isPrepay && studentPreviewImageUrl && (
            <StudentPreviewContainer className="bnl-product-config__selected-pose">
              <img
                className="bnl-product-config__selected-pose__image portrait-image"
                src={studentPreviewImageUrl}
              />
            </StudentPreviewContainer>
          )}
        </div>
        <div style={{ position: 'relative' }}>
          <div className="bnl-product-config__selected-background__image"></div>
          <div className="bnl-product-config__selected-background__desc">
            {backgroundOptionsCount > 1
              ? `Background ${index + 1}`
              : `Background`}
          </div>
        </div>
      </>
    );
  };

  const renderBackgroundPosesContainer = (
    toggleMobilePosesBackgroundsConfig = false,
    productBackgroundOptionsCount = 0,
    posesCount = 0,
  ) => {
    if (!productRequiresConfiguration) {
      return null;
    }

    // In some scenarios, the product will have one pose but two backgrounds...
    // we need to utilize the higher value of the two
    const greaterOfTheTwo =
      productBackgroundOptionsCount > posesCount
        ? productBackgroundOptionsCount
        : posesCount;

    const containers = [];

    for (let i = 0; i < greaterOfTheTwo; i++) {
      containers.push(
        <SelectedConfigWrapper
          onClick={() => {
            toggleMobilePosesBackgroundsConfig
              ? togglePosesBackgroundsSelector()
              : false;
            if (updatedPosesCount >= 1) {
              setShowPoseOptions(true);
            }
          }}
        >
          {renderPose(i)}
          {renderBackground(i)}
        </SelectedConfigWrapper>,
      );
    }

    return containers;
  };

  const renderPose = (index) => {
    const background = backgroundSelections[index]
      ? `url(${backgroundSelections[index]?.image_thumbnail})`
      : '';
    if (poseSelections.length > 0 && poseSelections[index]) {
      const pose = poseSelections[index];

      const sharedProps = {
        background,
        onClick: () => handleToggle(index),
        style: {
          outline: selectedIndex === index ? '2px solid #479de6' : 'none',
          outlineOffset: '2px',
        },
        isShifted: index > 0,
      };

      if (!!pose?.selected_crop) {
        return (
          <Box {...sharedProps}>
            <img src={pose.selected_crop.url} />
            {productBackgroundOptionsCount - 1 === index && (
              <BoxText>pose</BoxText>
            )}
          </Box>
        );
      }

      return (
        <Box {...sharedProps}>
          <img src={pose.url} />
          {productBackgroundOptionsCount - 1 === index && (
            <BoxText>pose</BoxText>
          )}
        </Box>
      );
    }

    if (!isPrepay && studentPreviewImageUrl) {
      return (
        <Box {...sharedProps}>
          <img src={studentPreviewImageUrl} />
          {productBackgroundOptionsCount - 1 === index && (
            <BoxText>pose</BoxText>
          )}
        </Box>
      );
    }

    if (!isPrepay) {
      return (
        <Box
          onClick={() => handleToggle(index)}
          style={{
            outline: selectedIndex === index ? '2px solid #479de6' : 'none',
            outlineOffset: '2px',
          }}
          isShifted={index > 0}
        >
          {productBackgroundOptionsCount - 1 === index && (
            <BoxText>pose</BoxText>
          )}
        </Box>
      );
    }

    return null;
  };

  const renderBackground = (index) => {
    if (backgroundSelections.length > 0 && backgroundSelections[index]) {
      return (
        <Box
          isShifted={index > 0}
          background={`url(${backgroundSelections[index]?.image_thumbnail})`}
        >
          {productBackgroundOptionsCount - 1 === index && (
            <BoxText>background</BoxText>
          )}
        </Box>
      );
    }

    if (productBackgroundOptionsCount > 0 && isGreenScreen) {
      return (
        <Box isShifted={index > 0}>
          {productBackgroundOptionsCount - 1 === index && (
            <BoxText>background</BoxText>
          )}
        </Box>
      );
    }

    return null;
  };

  const renderTapToConfigure = () => {
    if (!productRequiresConfiguration) {
      return null;
    }

    return (
      <>
        <div className="bnl-product-config__mobile-instruction">
          Tap to configure
        </div>
        {renderBackgroundPosesContainer(
          true,
          productBackgroundOptionsCount,
          posesCount,
        )}
      </>
    );
  };

  return (
    <SelectedPosesAndBackgroundsContainer>
      <div className="poses-backgrounds-container__mobile">
        {renderTapToConfigure()}
      </div>
      <div className="poses-backgrounds-container__desktop">
        {renderBackgroundPosesContainer(
          true,
          productBackgroundOptionsCount,
          posesCount,
        )}
      </div>
    </SelectedPosesAndBackgroundsContainer>
  );
};

export default SelectedPosesAndBackgrounds;
